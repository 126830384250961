import React from 'react'
import { Container } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import cn from 'classnames'

import Item from './Item'

import DATA from './constants'

import * as s from './About.module.scss'

const About = () => {
  return (
    <section id="stake" className={s.about}>
      <Container className={s.about__inner}>
        <div className={s.about__content}>
          <h2>
            Our goal is to <br />
            <span>deliver</span> 100% uptime
          </h2>
          <p className={cn('lead', s.about__lead)}>
            We are maintaining high efficiency thanks to our own bare metal
            server infrastructure, as well as devotion to the source code,
            continuous communication with core devs, and non-stop learning.
          </p>
          <div className="swiper-pagination" />
        </div>

        <Swiper
          className={s.about__items}
          slidesPerView="auto"
          spaceBetween={6}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          breakpoints={{
            640: {
              slidesPerView: 'auto',
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 'auto',
            },
            1200: {
              slidesPerView: 2,
            },
          }}
          modules={[Pagination]}
        >
          {DATA.map((props, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`slide${i}`}>
              <Item {...props} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  )
}

export default About
