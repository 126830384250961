import { useQuery } from '@tanstack/react-query'

const ApiValue = ({ value: placeholder, url, method, body, responseFn }) => {
  const { data } = useQuery(
    [url],
    () => fetch(url, { method, body }).then((res) => res.json()),
    {
      select: responseFn,
    }
  )

  return data || placeholder
}

export default ApiValue
