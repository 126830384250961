const DATA = [
  {
    label: 'Send us email',
    link: 'mailto:contact@prophet.one',
    icon: {
      name: 'icon-envelope',
      color: '#BAC8C4',
    },
  },
  {
    label: 'DM in Twitter',
    link: 'https://twitter.com/messages/compose?recipient_id=1185242107323277312',
    icon: {
      name: 'icon-twitter',
      color: '#12AAFF',
    },
  },
]

export default DATA
