import chainlink from './img/chainlink.svg'
import near from './img/near.svg'

const DATA = [
  {
    bg: '#CED67C',
    color: '#000',
    img: {
      src: chainlink,
      width: 46,
      height: 54,
    },
    title: 'Chainlink Node Operator',
    data: [
      {
        name: 'Chains',
        value: 'Ethereum, Fantom, Moonriver',
      },
      {
        name: 'Feeds',
        value: '500+',
      },
      {
        name: 'Products',
        value: 'Price Feeds, CCIP',
      },
    ],
    button: {
      label: 'Stake',
      link: 'https://blog.chain.link/how-to-stake-chainlink-link/',
    },
  },
  {
    bg: '#6F6F6F',
    color: '#fff',
    img: {
      src: near,
      width: 54,
      height: 54,
    },
    title: 'NEAR Protocol Validator',
    address: 'prophet.poolv1.near',
    data: [
      {
        name: 'Staked amount',
        value: '??? NEAR',
        // api: {
        //   method: 'post',
        //   url: 'https://near-overview.genesislab.net/api/',
        //   data: `{"jsonrpc":"2.0","method":"getNode","id":1,"params":{"account":"prophet.poolv1.near"}}`,
        //   responseFn: (res) =>
        //     `${(res?.result?.stake || 0) / 1e24 || '???'} NEAR`,
        // },
        // api: {
        //   url: 'https://near.zavodil.ru/pools.txt',
        //   responseFn: (res) =>
        //     `${Math.floor(res?.data?.find((item) => item.account_id === "prophet.poolv1.near").stake).toLocaleString()} NEAR`,
        // },
        api: {
          url: 'https://api.prophet.one/get-account-info',
          responseFn: (res) =>
            `${res?.totalStakedBalance?.toLocaleString()} NEAR`,
        },
      },
      {
        name: 'Delegators',
        value: 'Many',
        api: {
          url: 'https://api.prophet.one/get-account-info',
          responseFn: (res) => res?.numberOfAccounts?.toLocaleString(),
        },
        // api: {
        //   url: 'https://near.zavodil.ru/pools.txt',
        //   responseFn: (res) =>
        //     res?.data?.find((item) => item.account_id === "prophet.poolv1.near").number_of_accounts.toLocaleString(),
        // },
      },
      {
        name: 'Fees',
        value: '5%',
      },
      {
        name: 'Uptime',
        value: '100%',
      },
    ],
    button: {
      label: 'Delegate',
      link: 'https://medium.com/@prophet.one/how-to-delegate-near-to-prophet-one-96f4fa66db99',
    },
  },
]

export default DATA
