import React from 'react'
import { Button, Container } from 'react-bootstrap'

import Icon from '../Icon'

import DATA from './constants'

import * as s from './Contact.module.scss'

const Contact = () => {
  return (
    <section id="contact" className={s.contact}>
      <Container className={s.contact__inner}>
        <h2>Contact us to say hello</h2>
        <div className={s.contact__buttons}>
          {DATA.map(({ label, link, icon }) => (
            <Button key={icon.name} size="lg" href={link} target="_blank">
              {label} <Icon {...icon} size={40} />
            </Button>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default Contact
