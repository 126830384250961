import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Hero from '~components/Hero'
import About from '~components/About'
import Contact from '~components/Contact'

import SEO from '../components/seo'

const queryClient = new QueryClient()

const IndexPage = () => (
  <QueryClientProvider client={queryClient}>
    <Hero />
    <About />
    <Contact />
  </QueryClientProvider>
)

export const Head = () => <SEO />

export default IndexPage
