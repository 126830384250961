import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import cn from 'classnames'

import Icon from '~components/Icon'
import ApiValue from './ApiValue'

import * as s from './Item.module.scss'

const Item = ({
  bg: backgroundColor,
  color,
  img,
  title,
  address,
  data,
  button,
}) => {
  const [copied, setCopied] = useState(null)

  const handleCopy = (text) => {
    // if (!copied) {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopied(true)

        setTimeout(() => {
          setCopied(false)
        }, 3000)
      },
      () => {
        alert('Error occured')
      }
    )
    // }
  }

  return (
    <div className={s.item} style={{ backgroundColor, color }}>
      <div className={s.item__img}>
        <img {...img} alt={title} />
      </div>
      <h3>{title}</h3>
      <table className={s.table}>
        <tbody>
          {address && (
            <tr className={cn(s.table__address, { [s.copied]: copied })}>
              <th>Address:</th>
              <td>
                <button type="button" onClick={() => handleCopy(address)}>
                  <Icon size={26} name={`icon-${copied ? 'check' : 'copy'}`} />
                  <span>{address}</span>
                </button>
              </td>
            </tr>
          )}
          {data.map(({ name, value, api }) => (
            <tr key={name}>
              <th>{name}:</th>
              <td>{api ? <ApiValue {...{ ...api, value }} /> : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {button && (
        <Button
          size="arrow"
          href={button.link}
          target="_blank"
          className={s.item__button}
        >
          {button.label}
          <Icon name="icon-arrow" size={26} />
        </Button>
      )}
    </div>
  )
}

export default Item
